import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from '../src/pages/ErrorPage/ErrorPage';
import Shipment from './pages/Shipment/Shipment';
import Shipments from './pages/Shipments/Shipments';
import NewLabDemo from './pages/NewLabDemo/NewLabDemo';
import AddShipment from './pages/NewLabDemo/AddShipment';
import UpdateShipment from './pages/NewLabDemo/UpdateShipment';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword';

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'resetPassword',
        element: <ResetPassword />
      },
      {
        path: 'shipments',
        element: <Shipments />
      },
      {
        path: 'shipments/:shipmentId',
        element: <Shipment />
      },
      {
        path: 'admin',
        element: <NewLabDemo />
      },
      {
        path: 'admin/addShipment',
        element: <AddShipment />
      },
      {
        path: 'admin/updateShipment/:shipmentId',
        element: <UpdateShipment />
      }
    ]
  }
]);

createRoot(document.getElementById('root') as HTMLElement).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
