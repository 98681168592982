import { CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { FacilityType } from '../../types/Facility';
import { Modal } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import gate1 from '../../assets/gate1.png';
import gate2 from '../../assets/gate2.png';

const Facility = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [facility, setFacility] = useState<FacilityType | null>(null);
  const [showGateModal, setShowGateModal] = useState(false);
  const [showGateSuccess, setShowGateSuccess] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [gate, setGate] = useState('');
  const [pin, setPin] = useState('');
  //   const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    const fetchFacility = async () => {
      setLoading(true);
      try {
        setFacility({
          id: '123',
          name: 'Aurora',
          address: '330 E. Liberty, Ann Arbor, MI 48104',
          gate_count: 2
        });
      } catch (error) {
        setError('An error occurred while fetching the facility');
      }
      setLoading(false);
    };
    fetchFacility();
  }, []);

  const handleGateModalClose = () => {
    setShowGateModal(false);
  };

  const handleOpenGate = () => {
    setShowGateSuccess(true);
    setTimeout(() => {
      setShowGateSuccess(false);
      setGate('');
      setShowGateModal(false);
    }, 2000);
  };

  const handlePinModalClose = () => {
    setShowPinModal(false);
    setPin('');
    setGate('');
  };

  const handleGeneratePin = () => {
    setPin(Math.floor(1000 + Math.random() * 9000).toString());
  };

  //   const handleUpdateModalClose = () => {
  //     setShowUpdateModal(false);
  //   };

  const gates = [
    {
      value: 1,
      label: 'Aurora #1'
    },
    {
      value: 2,
      label: 'Aurora #2'
    }
  ];

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  if (!facility) {
    return <div>Facility not found</div>;
  }

  return (
    <div className="p-5">
      <div className="shadow bg-[#FBFBFB] p-3">
        <div className="flex">
          <h1 className="font-bold text-xl mb-3">Facility Info:</h1>
          <span className=" text-sky-600 ml-auto hover:underline cursor-pointer">
            Edit
          </span>
        </div>
        <p>
          <span className="font-bold">Name: </span>
          {facility.name}
        </p>
        <p>
          <span className="font-bold">IDs: </span>
          {facility.id}, KHCAU
        </p>
        <p>
          <span className="font-bold">Address: </span>
          {facility.address}
        </p>
        <p>
          <span className="font-bold"># of Gates: </span>
          {facility.gate_count}
        </p>
        <p>
          <span className="font-bold">Open Gate Hours: </span>
          0800-1700 CST
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-5 mb-3">Actions:</h1>
        <div className="flex gap-3">
          <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
            onClick={() => setShowGateModal(true)}
          >
            Open Gate
          </button>
          <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
            onClick={() => setShowPinModal(true)}
          >
            Generate Gate PIN
          </button>
          {/* <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
            onClick={() => setShowUpdateModal(true)}
          >
            Update Facility
          </button> */}
        </div>
      </div>
      <div className="flex gap-5">
        <div className="w-1/2">
          <h1 className="font-bold text-lg mt-5 mb-3">On-site Cameras</h1>
          <div className="flex gap-5">
            <div className="bg-white p-4 min-h-52">
              <h1>Camera #1</h1>
              <img src={gate1} alt="gate1" className="w-full" />
            </div>
            <div className="bg-white p-4 min-h-52">
              <h1>Camera #2</h1>
              <img src={gate2} alt="gate2" className="w-full" />
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <h1 className="font-bold text-lg mt-5 mb-3">On-site Gates</h1>
          <div className="flex gap-5">
            <div className="bg-white p-4 min-h-40 w-1/2">
              <h1>Gate #1</h1>
              <p>Status: Closed</p>
            </div>
            <div className="bg-white p-4 min-h-40 w-1/2">
              <h1>Gate #2</h1>
              <p>Status: Closed</p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="font-bold text-lg mt-5 mb-3">Site-Specific Messages</h1>
      <div className="bg-white p-4 w-full">
        <span className=" text-sky-600 float-right hover:underline cursor-pointer">
          Edit
        </span>
        <p>
          <span className="font-bold">Load # Format:</span> Please enter your load number
          below. Possible Load # Format(s): Starts with “2” (9 digits after 2)
        </p>
        <p>
          <span className="font-bold">Successful Check-in: </span>You will receive
          information about where to drop your empty, where to dock your truck and/or
          where to pick up your loaded trailer via text message
        </p>
        <p>
          <span className="font-bold">Check-In with an Issue: </span>Unfortunately there
          was an issue with your check-in. Once you have entered the gate, please proceed
          to the dock office and show them this receipt
        </p>
        <p>
          <span className="font-bold">Drop: </span>Please drop your trailer in spot XX.
        </p>
        <p>
          <span className="font-bold">Live Pickup: </span> Please back into dock XX.
        </p>
        <p>
          <span className="font-bold">Live Delivery: </span>Please back into dock XX.
        </p>
        <p>
          <span className="font-bold">Hook: </span>Please pick up your trailer in spot XX.
        </p>
        <p>
          <span className="font-bold">Drop & Hook: </span>Please drop your trailer in spot
          XX and pick up your loaded trailer in spot YY.
        </p>
        <p>
          <span className="font-bold">After Hours Message: </span>We are closed. Please
          return later.
        </p>
      </div>
      {showGateModal && (
        <Modal
          open={showGateModal}
          onClose={handleGateModalClose}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleGateModalClose}
            />
            {!!showGateSuccess ? (
              <div className="flex justify-center items-center flex-col mt-5">
                <h1 className="text-xl text-green-500">{gate} Opened!</h1>
                <Check className="text-green-500 mt-2" fontSize="large" />
              </div>
            ) : (
              <>
                <h1 className="text-xl mb-5">Open Gate</h1>
                <h1 className="text-xl mb-5">Please select which gate to open:</h1>
                <Select
                  options={gates}
                  placeholder="Gates"
                  onChange={(selectedOption: any) => {
                    setGate(selectedOption?.label);
                  }}
                />
                <div className="flex justify-between mt-3">
                  <button
                    className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24"
                    onClick={handleGateModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    className={twMerge(
                      'bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24',
                      !gate && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                    )}
                    onClick={handleOpenGate}
                    disabled={!gate}
                  >
                    Open
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
      {showPinModal && (
        <Modal
          open={showPinModal}
          onClose={handlePinModalClose}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handlePinModalClose}
            />
            {pin ? (
              <div className="flex justify-center items-center flex-col">
                <h1 className="text-xl mb-2">Please use this PIN at {gate}:</h1>
                <h1 className="text-xl">{pin}</h1>
                <button
                  className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-10"
                  onClick={handlePinModalClose}
                >
                  Close
                </button>
              </div>
            ) : (
              <>
                <h1 className="text-xl mb-5">Generate PIN</h1>
                <h1 className="text-xl mb-5">
                  Please select which gate to provide a PIN for:
                </h1>
                <Select
                  options={gates}
                  placeholder="Gates"
                  onChange={(selectedOption: any) => {
                    setGate(selectedOption?.label);
                  }}
                />
                <div className="flex justify-between mt-3">
                  <button
                    className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24"
                    onClick={handlePinModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    className={twMerge(
                      'bg-sky-600 hover:bg-sky-500 text-white p-2 rounded w-24',
                      !gate && 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                    )}
                    onClick={handleGeneratePin}
                    disabled={!gate}
                  >
                    Generate
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
      )}
      {/* {showUpdateModal && (
        <Modal
          open={showUpdateModal}
          onClose={handleUpdateModalClose}
          className="flex justify-center items-center"
        >
          <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
            <Close
              className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
              onClick={handleUpdateModalClose}
            />
            <h1 className="text-xl mb-5">Update Facility</h1>

            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-3"
              onClick={handleUpdateModalClose}
            >
              Cancel
            </button>
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded mt-3"
              onClick={handleUpdateModalClose}
            >
              Update
            </button>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default Facility;
