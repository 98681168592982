import { CircularProgress } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import axios from 'axios';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

type PhotoType = {
  id: string;
  exception: string;
  image: string;
  message: string;
  timestamp: string;
};
interface PhotoFeedProps {
  photos: PhotoType[];
  loading: boolean;
}

const PhotoFeed = ({ photos, loading }: PhotoFeedProps) => {
  if (loading) {
    return (
      <div className="p-3">
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="p-3 flex flex-col gap-4">
      {photos.map((photo: PhotoType) => (
        <Row key={photo.id} photo={photo} />
      ))}
    </div>
  );
};

const Row = ({ photo }: { photo: PhotoType }) => {
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const timeAgo = (timestamp: any) => {
    const now = new Date();
    const time = new Date(timestamp);
    // @ts-ignore
    const diff = now - time; // difference in milliseconds

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Rough approximation
    const years = Math.floor(days / 365); // Rough approximation

    if (seconds < 60) {
      return `Uploaded ${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `Uploaded ${minutes} minutes ago`;
    } else if (hours < 24) {
      return `Uploaded ${hours} hours ago`;
    } else if (days < 7) {
      return `Uploaded ${days} days ago`;
    } else if (weeks < 4) {
      return `Uploaded ${weeks} weeks ago`;
    } else if (months < 12) {
      return `Uploaded ${months} months ago`;
    } else {
      return `Uploaded ${years} years ago`;
    }
  };

  const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);

    // Extract day, month, hours, and minutes
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // e.g., "Jun"
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Format as "03 Jun 18:22"
    return `${day} ${month} ${hours}:${minutes}`;
  };

  const handleDelete = async (id: string) => {
    setDeleting(true);
    try {
      await axios.put(`${WRITE_API_URL}/api/v1/admin/photo/${id}/deactivate`);
      setDeleted(true);
    } catch (error) {
      console.error(error);
    }
    setDeleting(false);
  };

  return (
    <div className={twMerge('flex', deleted ? 'hidden' : '')}>
      <img
        src={photo.image}
        alt={photo.image}
        className="mr-2 max-w-36 md:max-w-44 lg:max-w-36 xl:max-w-60"
      />
      <div>
        <p>{timeAgo(photo.timestamp)}</p>
        <p className="flex items-center">
          <CalendarMonth fontSize="small" className="mr-1" />
          {formatTimestamp(photo.timestamp)}
        </p>
        {!!photo.message && <p>{photo.message}</p>}
        {!!photo.exception && <p className="text-red-500 font-bold">{photo.exception}</p>}
      </div>
      <div className="flex flex-col ml-auto text-right">
        <button
          className="bg-sky-600 hover:bg-sky-500 text-white p-1 rounded"
          onClick={() => handleDelete(photo.id)}
          disabled={deleting}
        >
          {deleting ? (
            <>
              Deleting...{' '}
              <CircularProgress
                style={{
                  color: 'white',
                  height: '15px',
                  width: '15px',
                  marginLeft: '4px'
                }}
              />
            </>
          ) : (
            'Delete'
          )}
        </button>
      </div>
    </div>
  );
};

export default PhotoFeed;
