export interface ShipmentType {
  id: string;
  tnumber: string;
  driver_name: string;
  implifiTruckNumMatch: boolean;
  ocrTrailerNumMatch: boolean;
  ocrLicenseNumMatch: boolean;
  check_in: boolean;
  inspected: boolean;
  dockConfirmation: number | null;
  ship_sig: boolean;
  drv_sig: boolean;
  check_out: boolean;
  ship_from: string;
  ship_to: string;
  estimated_bol_date: string;
  carrier_name: string;
  messages: ShipmentMessageType[];
  photos_count: number;
  shipment_status: ShipmentStatusType;
}

//  shipment statuses from backend: truck_found, canceled, in_transit
export enum ShipmentStatusType {
  canceled = 'canceled',
  truckFound = 'truck_found',
  inTransit = 'in_transit'
}

export interface ShipmentMessageType {
  id: string;
  body: string;
  createdAt: string;
}

export interface ShipmentPhotoType {
  id: string;
  url: string;
  createdAt: string;
}
