import { useState, createContext, useCallback, useMemo, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './routes/ProtectedRoute';
import RedirectRoute from './routes/RedirectRoute';
import NavBar from './components/NavBar/NavBar';
import SidePanel from './components/SidePanel/SidePanel';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword';
import Shipment from './pages/Shipment/Shipment';
import Shipments from './pages/Shipments/Shipments';
import Users from './pages/Users/Users';
import Facilities from './pages/Facilities/Facilities';
import Facility from './pages/Facility/Facility';
import axios from 'axios';
import { logout } from './services/authService';
import UnassginedSlotDrivers from './pages/UnassignedSlotDrivers';
import { twMerge } from 'tailwind-merge';
import User from './pages/User/User';

export const UserContext = createContext({});

interface UserType {
  id: number;
  name: string;
  email: string;
  roles: string[];
  canAccessUserManagement: boolean;
}
// interface UserContextType {
//   user: User | null;
//   setUser: () => void;
//   unsetUser: () => void;
// }

const App = () => {
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authCookie = document.cookie.split(';').find((cookie) => {
      return cookie.includes('authToken');
    });
    const userFromStorage = localStorage.getItem('user');
    if (authCookie && userFromStorage) {
      const authToken = authCookie.split('=')[1];
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      const user = JSON.parse(userFromStorage);
      setUser(user);
    } else {
      logout();
    }
    setLoading(false);
  }, []);

  const handleSetUser = useCallback((user: UserType) => {
    const { id, name, roles, email, canAccessUserManagement } = user;
    setUser({ id, name, roles, email, canAccessUserManagement });
  }, []);
  const handleUnsetUser = useCallback(() => setUser(null), []);

  const userContextValue = useMemo(
    () => ({ user, setUser: handleSetUser, unsetUser: handleUnsetUser }),
    [user, handleSetUser, handleUnsetUser]
  );

  const toggleSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };

  const userFromStorage = localStorage.getItem('user');
  let allowUser = false;
  if (!!userFromStorage) {
    const allowedUsers = ['chad.marks@bluetriton.com', 'anthony.cox@bluetriton.com'];
    const userEmail = JSON.parse(userFromStorage).email;
    if (
      allowedUsers.includes(userEmail) ||
      userEmail.split('@')[1] === 'freightroll.com'
    ) {
      allowUser = true;
    }
  }

  const canViewFacilities = true;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <UserContext.Provider value={userContextValue}>
      <div className="bg-[#EEEEEE] min-h-screen flex">
        {user && (
          <SidePanel
            showing={showSidePanel}
            showSlotlessDrivers={allowUser}
            showUserManagement={user.canAccessUserManagement}
            canViewFacilities={canViewFacilities}
          />
        )}
        <div
          className={twMerge(
            'w-full',
            showSidePanel ? 'ml-48 w-[calc(100vw-192px)]' : ''
          )}
        >
          {user && <NavBar toggleSidePanel={toggleSidePanel} />}
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route element={<ProtectedRoute isAllowed={!!userFromStorage} />}>
              <Route path="/" element={<Shipments />} />
              <Route path="shipments" element={<Shipments />} />
              <Route path="shipments/:shipmentId" element={<Shipment />} />
              <Route path="account" element={<User />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed={allowUser} />}>
              <Route path="drivers/unassigned_slot" element={<UnassginedSlotDrivers />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed={user?.canAccessUserManagement} />}>
              <Route path="users" element={<Users />} />
              <Route path="users/:userId" element={<User />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed={canViewFacilities} />}>
              <Route path="facilities" element={<Facilities />} />
              <Route path="facilities/:facilityId" element={<Facility />} />
            </Route>
            <Route
              element={
                <ProtectedRoute isAllowed={!!user && user.roles.includes('admin')} />
              }
            ></Route>
            <Route element={<RedirectRoute isAllowed={!!user} />}>
              <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
          </Routes>
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default App;
